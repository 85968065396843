.contact-body {
    font-size: 0.8em;
    color: white;
}

#contact {
    min-height: 500px;
    padding-left: 5%;
    padding-right: 5%;
}

.contact-title {
    font-size: 2em;
    color: #fff!important;
}

#contact h3 {
    color: #df9527;
    font-size: 0.8em;
}