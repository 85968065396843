.intro-top {
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

.intro-main {
    min-height: 300px;
}

.intro-name {
    font-size: 2.5em;
    color: #fff!important;
    /* font-family: Caveat, cursive */
}

.intro-text {
    font-size: 1.4em;
    color: lightgray;
}


.intro-text-main {
    font-size: 0.8em;
    color: #fff;
}

.intro-img {
    max-width: 350px;
    justify-content: center;
    padding-top: 0%;
    border-radius: 50%;  
}

.intro-button {
    justify-content: center;
}
