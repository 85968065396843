.experience-main {
    min-height: 300px;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

.experience-title {
    color: #fff;
    font-size: 1.2em;
}

.experience-body {
    color: #2C5880;
    font-size: 0.8em;
}

.experience-tools {
    color: #152a5c;
    font-size: 0.8em;
}

.experience-main-title {
    font-size: 2em;
    color: #fff!important;
}
