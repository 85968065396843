.project-title {
    color: #fff!important;
    font-size: 0.8em;
}

.project-body {
    color: #2C5880;
    font-size: 0.8em;
}

.project-tools {
    color: #152a5c;
    font-size: 0.8em;
}

.projects-main-title {
    font-size: 2em;
    color: #fff!important;
}

#projects {
    min-height: 500px;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

.modal-link {
}

.algorithms {
    max-width: 700px;
    max-height: 400px;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #D5AA48!important;
    opacity: 1;
    transition: 0.3s;
    -webkit-transition: 170ms ease-in-out;
    -moz-transition: 170ms ease-in-out;
    -ms-transition: 170ms ease-in-out;
    -o-transition: 170ms ease-in-out;
    transition: 170ms ease-in-out;
    transition-duration: 170ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.project-block {
    font-size: 0.8em;
}