
.nav-link {
    color: #2C5880!important;
    font-variant: small-caps;
}

/* .nav-link:active {
    color: #2b5bd6!important;
} */

.nav-link.active {
    color: #D5AA48!important;
}

.nav-link:hover {
    color: #D8CF6C!important;
    opacity: 1;
    transition: 0.3s;
    -webkit-transition: 170ms ease-in-out;
    -moz-transition: 170ms ease-in-out;
    -ms-transition: 170ms ease-in-out;
    -o-transition: 170ms ease-in-out;
    transition: 170ms ease-in-out;
    transition-duration: 170ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.header-link {
    text-transform: capitalize!important;
    font-size: 1em;
    font-weight: 600;
}