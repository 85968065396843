/* .footer {
  width: 100%;
  text-align: right;
  font-size: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-top: 1rem;
  padding: 1rem;
  color: lightgray;
} */

.footer-chris {
  color: rgba(56, 56, 56, 1);
  font-size: 12px;
}

a {
  margin-right: 20px;
  color: #F3A200;

}

.nav-icons {
  font-size: 20px;
}

.icon-1 {

}

.icon-2 {
  
}

.icon-3 {
  
}