

.App-link {
  color: #61dafb;
}


.App {
  min-height: 400px;
}

.main {
  /* background-color: #0D1119; */
  /* font-family: 'Caveat', cursive; */
  /* font-family: 'Padauk', sans-serif; */
  font-variant: small-caps;
  letter-spacing: 3px;
}


/* 
@font-face {
  font-family: 'Castoro', serif;
  src: local('Castoro'), url('../src/fonts/Castoro-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  src: local('Open Sans'), url('../src/fonts/OpenSans-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Padauk', sans-serif;
  src: local('Padauk'), url('../src/fonts/Padauk-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Ubuntu', sans-serif;
  src: local('Ubuntu'), url('../src/fonts/Ubuntu-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Exo2', sans-serif;
  src: local('Exo2'), url('../src/fonts/Exo2-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Caveat', sans-serif;
  src: local('Caveat'), url('../src/fonts/Caveat-Regular.ttf') format('opentype');
} */




hr {
  border-top: 1px solid #55451f;
}